<template>
  <div v-loading="loading" class="order-detail-container">
    <div class="buttonbc">
      <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
        <img src="@/assets/back.png" alt="">
      </el-button>
    </div>
    <div class="cont">
      <br />
      <br />
      <h4>基本信息</h4>
      <table class="table-cont">
        <tbody>
          <tr>
            <td class="details-div-span">
              交易状态：<span class="detail-span1">{{ orderDetail.trade_status_text }}</span>
              <span style="color: red;">
                {{ orderDetail.hangup_status === 1 ? '(店铺挂起)' :
    (orderDetail.hangup_status === 2 ? '(企业挂起)' : '') }}
              </span>
            </td>
          </tr>
          <tr>
            <td>下单人信息：</td>
          </tr>
          <!-- 站点 -->
          <tr class="detail-tr1">
            <td>下单人姓名：{{ this.$store.getters.shopInfo.shop_type == 7 ? orderDetail.uname : orderDetail.nickname }}</td>
            <td>下单人手机号：{{ orderDetail.mobile }}</td>
          </tr>
          <tr>
            <td>订单信息：</td>
          </tr>
          <tr class="detail-tr1" v-if="supplementSn">
            <td style="color: red;">补单订单编号：{{ supplementSn }}</td>
          </tr>
          <tr class="detail-tr1" v-if="renNewSn">
            <td style="color: red;">换新订单编号：{{ renNewSn }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>用户下单时间：{{ orderDetail.create_time | unixToDate }}</td>
            <td>用户支付方式：{{ orderDetail.payment_method_name }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>卡券卡号：{{ orderDetail.card_code | formatCardNo }}</td>
            <td>
              <!--              订单总价：{{ orderDetail.shop_total_price | unitPrice("￥") }}-->
              <!--              (商品: {{ 0 | unitPrice("￥") }} + 运费: {{ orderDetail.shop_freight_price | unitPrice("￥") }})-->
            </td>
          </tr>
          <tr class="detail-tr1">
            <td>
              商城支付金额：{{ orderDetail.enterprise_goods_price + orderDetail.enterprise_freight_price | unitPrice("￥") }}
              (商品: {{ orderDetail.enterprise_goods_price | unitPrice("￥") }} + 运费: {{
    orderDetail.enterprise_freight_price
    | unitPrice("￥") }})
            </td>
            <td>商城支付时间：{{ orderDetail.payment_time | unixToDate }}</td>
          </tr>
          <tr>
            <td>收货人信息：</td>
          </tr>
          <tr class="detail-tr1">
            <td>收货人：{{ orderDetail.consignee_name }}</td>
            <td>收货人手机号：{{ orderDetail.consignee_mobile }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>
              收货地址：{{ orderDetail.consignee_province
              }}{{ orderDetail.consignee_city }} {{ orderDetail.consignee_county }}
              {{ orderDetail.consignee_town || "" }}{{ orderDetail.consignee_address }}
            </td>
          </tr>
          <tr>
            <td>
              备注信息：
            </td>
          </tr>
          <tr class="detail-tr1" style="display:inline-block; max-width: 25vw">
            <td v-if="orderDetail.remark">
              {{ orderDetail.remark }}
            </td>
            <td v-else>无</td>
          </tr>
        </tbody>
      </table>
      <br />
      <h4>卡券信息</h4>
      <div class="combo-info">
        <el-row :gutter="20">
          <el-col :span="6">
            卡券名称：{{ comboInfo.card_name }}
          </el-col>
          <el-col :span="6">
            卡号：{{ comboInfo.card_code | formatCardNo }}
          </el-col>
          <el-col :span="6">
            卡券面值：{{ comboInfo.card_value }}
          </el-col>
          <el-col :span="6">
            卡券类型：{{ comboInfo.card_type == 6 ? '计次卡' : (comboInfo.card_type == 5 ? '智能套餐卡' : '宅配卡') }}
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            {{ comboInfo.card_type == 6 || comboInfo.card_type == 5 ? '兑换礼包名' : '配送礼包名称' }}：{{ comboInfo.sho_combo_name
            }}
          </el-col>
          <el-col :span="8">
            卡号所属公司：{{ comboInfo.company }}
          </el-col>
          <el-col :span="8" v-if="comboInfo.card_type == 7">
            卡券配送周期：{{ comboInfo.card_kind | filterKind }} {{ `(${comboInfo.available_times}次配送)` }}
          </el-col>
        </el-row>
      </div>
      <br />
      <h4>商品信息</h4>
      <template>
        <el-table :data="productList" :header-cell-style="{ background: '#cccccc' }" :expand-row-keys="rowKeysList"
          row-key="sku_sn">
          <el-table-column prop="goods_image" label="商品详情" width="180" fixed="left">
            <template slot-scope="scope">
              <div style="display: flex; flex-direction: row; flex-wrap: nowrap;">
                <img :src="scope.row.goods_image" class="goods-image" style="margin-right: 15px;" />
                <el-tooltip class="item" effect="light" :content="scope.row.name" placement="top">
                  <el-button style="max-width: 19vw; overflow: hidden; text-overflow: ellipsis;" class="shop-name"
                    type="text">{{ scope.row.name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}</el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="商品前端名称" property="goods_alias" show-overflow-tooltip></el-table-column>
          <el-table-column prop="order_source" label="商品来源" width="100">
            <template #default="{ row }">
              {{ row.order_source === 0 ? '平台' : row.order_source === 1 ? '京东' : '自有' }}
            </template>
          </el-table-column>
          <el-table-column prop="seller_name" v-if="MixinIsFormEnterprise" label="所属供应商" show-overflow-tooltip />
          <el-table-column prop="num" label="数量" width="60"></el-table-column>
          <el-table-column prop="mktprice" label="市场价" width="100" />
          <el-table-column label="会员价" v-if="parentLogin === 1" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.platform_price | unitPrice("￥") }}</span>
            </template>
          </el-table-column>
          <!-- 站点展示销售价 -->
          <el-table-column label="销售价" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.shop_purchase_price | unitPrice("￥") }}</span>
            </template>
          </el-table-column>
          <!-- 站点展示分销价，供应商不展示分销价 -->
          <el-table-column label="分销价" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.enterprise_purchase_price | unitPrice("￥") }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="shop_total_price" label="物流状态">
            <template slot-scope="{row}">
              <span v-if="row.logistics_status_text">{{ row.logistics_status_text }}</span>
              <span v-else>{{ row.state === 0 ? '未发货' : (row.state === 2 ? '已发货' : '已收货') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="维权状态">
            <template slot-scope="{row}">
              {{ row.service_status | service_status }}
            </template>
          </el-table-column>
          <el-table-column label="京东单号" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.ext_order_id">{{ scope.row.ext_order_id }}</span>
              <span v-else>——</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="220" v-if="orderDetail.trade_kind !== 4" fixed="right">
            <template #default="{ row }">
              <el-tooltip class="item" effect="dark" :hide-after="1000" content="该功能按钮仅用于处理物流存在疑难的商品" placement="top">
                <el-button size="small" type="primary" v-if="row.is_receivin === 1" @click="confirmReceipt(row)">
                  确认收货
                </el-button>
              </el-tooltip>
              <el-button size="small" type="primary" v-if="row.state === 2 && row.service_status === 'NOT_APPLY'"
                @click="handleManualAfterSale(row)">
                人工转售后
              </el-button>
              <el-button size="small" type="primary"
                v-if="row.state === 0 && row.service_status === 'NOT_APPLY' && row.change_goods === 1 && orderDetail.trade_status !== 'CANCELLED' && (!row.new_trade || (row.new_trade && row.new_trade.trade_status === 'CANCELLED'))"
                @click="handleOpenSelectOtherDialog(row)">
                换其他商品
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <h4 v-if="expressInfo.length !== 0">包裹信息</h4>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="(item, index) in expressInfo" :key="index" :label="item.message" :name="item.index">
          <p style="background-color: #6666; height: 30px; line-height: 30px;">
            <span>快递公司: {{ item.deliver_name }}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>物流单号: {{ item.delivery_no }}</span>
          </p>
          <div style="display: flex;">
            <div v-for="(i, index) in item.images" :key="index"
              style="width: 100px; height: 100px; margin-right: 20px;">
              <img :src="i" style="width: 100%; height: 100%;">
            </div>
          </div>
          <div class="logistics-info" v-if="item.data.code === 200">
            <p v-if="!item.data.data[0].express.data" style="display:block; line-height: 20px;">
              <!-- 判断物流是否超过三个月 expired==1（超过三个月） -->
              <span v-if="item.expired == 1">商家已发货，由于时间过长，已无法为您提供快递的物流信息</span>
              <span v-else>抱歉，暂时没有查到相关物流信息，请耐心等待。</span>
              <!-- <span v-else>{{ item.data.data[0].express.message || item.data.data[0].express }}</span> -->
            </p>
            <ul v-else>
              <li v-for="(ship, index) in item.data.data[0].express.data" :key="index" class="ship-item">
                <i class="ship-dot"></i>
                <div class="ship-msg">
                  <p class="ship-msg-text">{{ ship.context }}</p>
                  <p class="ship-msg-time">{{ ship.time }}</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="logistics-info" v-else>
            <p v-if="!item.data.data.orderTrack" style="display:block; line-height: 20px;">{{ item.data.data.message }}
            </p>
            <ul v-else>
              <li v-for="(ship, index) in item.data.data.orderTrack" :key="index" class="ship-item">
                <i class="ship-dot"></i>
                <div class="ship-msg">
                  <p class="ship-msg-text">{{ ship.content }}</p>
                  <p class="ship-msg-time">{{ ship.msgTime }}</p>
                </div>
              </li>
            </ul>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!--    人工转售后-->
    <x-dialog :proxy="manualAfterSaleProxy">
      <el-form :model="applySaleParams" :rules="applySaleRules" ref="applySaleForm">
        <el-form-item label="售后类型" label-width="80px">
          <span>选择商品</span>
        </el-form-item>
        <el-form-item label="申请原因" label-width="80px" prop="refund_reason">
          <el-select v-model="applySaleParams.refund_reason" style="width: 180px;" size="small">
            <el-option value="" label="请选择申请原因"></el-option>
            <el-option value="用户拒收" label="用户拒收"></el-option>
            <el-option value="用户地址填写错误" label="用户地址填写错误"></el-option>
            <el-option value="其他" label="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="问题描述" label-width="80px" prop="customer_remark">
          <el-input type="textarea" :rows="2" placeholder="请输入问题描述" maxlength="500" show-word-limit
            v-model="applySaleParams.customer_remark">
          </el-input>
        </el-form-item>
        <el-form-item label-width="80px">
          <el-upload :action="`${MixinUploadApi}`" :before-upload="handleBeforeUpload" :on-success="handleUploadSuccess"
            :on-remove="handleUploadRemove" :on-exceed="handleUploadExceed" list-type="picture-card"
            :file-list="fileList" :limit="9">
            <div slot="trigger" class="custom-upload">
              <i class="el-icon-camera-solid"></i>
              <p>上传凭证</p>
              <p>(最多9张)</p>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
    </x-dialog>

    <!--    选择其他商品-->
    <x-dialog :proxy="selectOtherGoodsProxy">
      <el-form v-model="selectOtherGoodsForm">
        <el-form-item label="选择的商品" label-width="120px">
          <div style="display: flex; align-items: center;">
            <span style="max-width: 40%; display: inline-block; margin-right: 10px;" class="text-ellipsis">
              {{ selectGoods.goods_name }}
            </span>
            <el-button type="primary" size="small" @click="handleOpenGoodsPicker">选择商品</el-button>
          </div>
        </el-form-item>
        <!--        <el-form-item label="商品数量" label-width="120px">-->
        <!--          <el-input-number v-model="selectGoods.goods_num" size="small" :min="1" label="描述文字"></el-input-number>-->
        <!--        </el-form-item>-->
        <el-form-item label="订单备注信息" label-width="120px">
          <el-input type="textarea" :rows="2" placeholder="请输入备注信息" v-model="selectOtherGoodsForm.remark">
          </el-input>
        </el-form-item>
        <el-form-item label="选择收货地址" label-width="120px">
          <div class="address-list">
            <div class="title">
              <h4>收货人信息</h4>
              <el-button type="primary" size="mini" @click="handlerAddAddressDialog(1)">添加收货地址</el-button>
            </div>
            <div class="address-item" v-for="item in receiveAddressList" :key="item.addr_id">
              <div class="name-and-province" @click="handlerSelectAddress(item.addr_id)">
                {{ item.name }} {{ item.province }}
                <i class="el-icon-check" v-show="item.addr_id === selectAddressId"></i>
              </div>
              <span class="inline-block-span">{{ item.name }}</span>
              <span class="inline-block-span">{{ item.province }} {{ item.city }} {{ item.county }} {{ item.town }} {{
    item.addr }}</span>
              <span class="inline-block-span">{{ item.mobile }}</span>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </x-dialog>
    <!--&lt;!&ndash;    选择商品 &ndash;&gt;-->
    <!--    <en-goods-picker-fx-->
    <!--      :show="display"-->
    <!--      :showChooseBtn="!multiple"-->
    <!--      :goods-api=`/seller/shopCombo/replaceGoodsListTh/${changeGoodsComboId}`-->
    <!--      :default-data="selectedGoodsList"-->
    <!--    />-->
    <x-dialog :proxy="selectChangeGoods">
      <en-table-layout :table-data="changeGoods.data">
        <template slot="table-columns">
          <el-table-column label="图片" width="80">
            <template slot-scope="scope">
              <img :src="scope.row.thumbnail" alt="" class="goods-image">
            </template>
          </el-table-column>
          <el-table-column label="前端图片" width="100">
            <template slot-scope="scope">
              <img :src="scope.row.web_thumbnail || scope.row.thumbnail" alt="" class="goods-image">
            </template>
          </el-table-column>
          <el-table-column label="商品名称" prop="goods_name" />
          <el-table-column label="前端商品名称" prop="web_goods_name" />
          <el-table-column label="销售价" prop="revise_price" width="80" />
          <el-table-column label="分销价" prop="enterprise_revise_price" width="80" />
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" v-if="selectGoods.goods_id === scope.row.goods_id">已选择</el-button>
              <el-button type="primary" size="mini" v-else @click="handleSelectGoods(scope.row)">选择</el-button>
            </template>
          </el-table-column>
        </template>
      </en-table-layout>
    </x-dialog>
    <!--    添加收货地址-->
    <x-dialog :proxy="addAddressDialog">
      <el-form :model="addAddressForm" label-width="80px" :rules="addAddressRules" ref="addAddressForm">
        <el-form-item label="所在地区">
          <en-region-picker :api="MixinRegionApi" @changed="handlerAddressSelectorChange"></en-region-picker>
        </el-form-item>
        <el-form-item label="收货人" prop="name">
          <el-input v-model="addAddressForm.name" size="small"></el-input>
        </el-form-item>
        <el-form-item label="详细地址" prop="addr">
          <el-input v-model="addAddressForm.addr" size="small"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input v-model="addAddressForm.mobile" size="small"></el-input>
        </el-form-item>
      </el-form>
    </x-dialog>
  </div>
</template>

<script>
import * as API_order from "@/api/order";
import * as API_Goods from '@/api/goods';
import XDialog from "@/components/x-dialog/x-dialog.vue";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
import request from "@/utils/request";

export default {
  name: "orderDetail",
  components: {
    XDialog,
    EnTableLayout
  },
  data () {
    return {
      rowKeysList: [],
      /** 列表loading状态 */
      loading: false,
      dialogApply: false,
      /** 换新订单编号 */
      renNewSn: '',
      /** 补单订单编号 */
      supplementSn: '',
      /** 订单详情数据 */
      orderDetail: {},
      productList: [],
      fhProductList: [],
      /*  订单编号 */
      sn: "",
      expressInfo: [],
      activeName: '1',
      dialogVisible: false,
      applySaleParams: {
        order_sn: '',
        sku_id: '',
        refund_reason: '',
        customer_remark: '',
        refuse_type: 'SHOP_REFUND'
      },
      parentLogin: 1,
      type: 0,
      comboInfo: {},
      //  人工转售后
      manualAfterSaleProxy: $xDialog.create({
        title: '人工转售后',
        showClose: true,
        width: '30vw',
        beforeConfirm: () => this.handlerManualAfterSaleConfirm(),
        beforeCancel: () => this.handleCloseDialog(),
        beforeClose: () => this.handleCloseDialog(),
      }),
      applySaleRules: {
        refund_reason: [
          { required: true, message: '请选择申请原因', trigger: 'change' }
        ],
        customer_remark: [
          { required: true, message: '请填写问题描述', trigger: 'blur' }
        ]
      },
      fileList: [],
      //  选择其他商品
      selectOtherGoodsProxy: $xDialog.create({
        title: '选择其他商品',
        width: '50vw',
        showClose: true,
        beforeClose: () => this.handleCloseSelectOtherDialog(),
        beforeCancel: () => this.handleCloseSelectOtherDialog(),
        beforeConfirm: () => this.handleConfirmSelectOtherDialog(),
      }),
      selectOtherGoodsForm: {
        remark: '',
        addr_id: '',
        trade_item_param_volist: [
          {
            source_goods_id: '',
            sku_id: ''
          }
        ],
        trade_kind: 3,
        source_order_sn: ''
      },
      selectChangeGoods: $xDialog.create({
        title: '更换商品',
        width: '60vw',
        disableConfirm: true,
        disableCancel: true,
        showClose: true,
        beforeClose: () => { this.selectChangeGoods.dismiss() }
      }),
      replaceGoodsParams: {
        goods_id: '',
        card_id: '',
        shop_combo_id: ''
      },
      changeGoods: [],
      receiveAddressList: [],
      selectAddressId: '',
      selectedGoodsList: [],
      selectGoods: {},

      /** 添加收货地址 */
      addAddressDialog: $xDialog.create({
        title: '新增收货地址',
        width: '35vw',
        showClose: false,
        beforeCancel: () => this.handlerAddAddressDialog(2),
        beforeConfirm: () => this.handlerAddAddressConfirm()
      }),
      addAddressForm: {
        name: '',
        addr: '',
        mobile: '',
        def_addr: 0,
        region: ''
      },
      addAddressRules: {
        name: [
          { required: true, message: '请输入收货人', trigger: 'blur' }
        ],
        addr: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
      },
      // newTradeSnList: '',
      changeGoodsComboId: ''
    };
  },
  mounted () {
    this.sn = this.$route.params.sn;
    this.GET_OrderDetail();
    API_Goods.getLoginType().then((res) => {
      this.parentLogin = res.parentLogin;
    });
  },
  filters: {
    filterKind (data) {
      switch (data) {
        case 1:
          return '月卡'
        case 2:
          return '季卡'
        case 3:
          return '年卡'
        case 4:
          return '自定义'
      }
    },
    service_status (status) {
      switch (status) {
        case 'APPLY':
          return '申请中';
        case 'NOT_APPLY':
          return '未申请';
        case 'PASS':
          return '申请通过';
        case 'REFUSE':
          return '审核拒绝';
        case 'CANCEL':
          return '申请取消';
        case 'COMPLETED':
          return '完成';
        case 'WAIT_SEND_BACK':
          return '待寄回';
        case 'SEND_BACK':
          return '买家寄回';
        case 'SELLER_SHIP':
          return '卖家发货';
        case 'SHOP_PASS':
          return '商家申请通过';
        default:
          return ''
      }
    }
  },
  methods: {
    /** 获取订单详情信息 */
    GET_OrderDetail () {
      this.loading = true;
      API_order.getAIComboShopTradeInfo(this.sn).then(res => {
        this.loading = false;
        this.productList = []
        this.orderDetail = res
        // this.newTradeSnList = res.new_trade_dos.map(item => item.trade_sn).join(',')
        //换新订单
        this.renNewSn = res.new_trade_dos && res.new_trade_dos.filter(item => item.trade_kind === 2 || item.trade_kind === 3).map(item => item.trade_sn).join(',');
        //补单订单
        this.supplementSn = res.new_trade_dos && res.new_trade_dos.filter(item => item.trade_kind === 4).map(item => item.trade_sn).join(',');
        this.orderDetail['payment_method_name'] = this.orderDetail.payment_method_name.replace(/,/g, '+')
        this.orderDetail['remark'] = this.orderDetail.order[0].remark
        res.order.forEach(item => {
          item.sku_list.forEach(i => {
            i.ext_order_id = item.ext_order_id
            i.order_source = item.order_source
            i['order_sn'] = item.sn
            this.productList.push(i)
          })
        })
        //#region 加载物流信息 start
        let params = {}
        params['order_sn'] = this.sn
        params['sku_sn'] = this.productList[0]['sku_sn']
        API_order.getAIComboShopLogistics(params).then(res => {
          try {
            if (res[0].data.data[0].express.data[0].statusCode == "2") res[0].data.data[0].express.data.unshift({ context: "快件存在疑难" });
          } catch (error) {

          }
          this.expressInfo = res
          this.expressInfo.forEach((item, index) => {
            item['message'] = '包裹' + String(index + 1)
            item['index'] = String(index + 1)
            if (item.data.code === 1) {
              item.data.data.orderTrack.reverse()
            }
          })
        })
        //#endregion 加载物流信息 end
        //获取卡券信息
        API_order.getComboInfo({ card_code: res.card_code, shop_id: res.shop_id }).then(response => {
          this.comboInfo = response;
        })

        this.getReceiveAddress()
      })
    },

    /** 获取用户的收货地址 */
    getReceiveAddress () {
      const _params = {
        member_id: this.orderDetail.member_id,
        card_key: this.orderDetail.card_key,
        shop_id: this.orderDetail.shop_id
      }
      API_order.getMemberAddressList(_params).then(res => {
        this.receiveAddressList = res;
        this.selectAddressId = res.filter(item => item.def_addr === 0)[0]?.addr_id ?? res[0].addr_id;
      })
    },
    /**确认收货 */
    confirmReceipt (row) {
      this.$confirm('当前商品物流信息存在疑难，确定要帮用户主动确认收货这个商品吗？', '确认收货', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        API_order.setRogGoods(row.order_sn, row.sku_id).then(res => {
          this.$message({
            type: 'success',
            message: '确认收货成功!'
          });
          this.GET_OrderDetail();
        })
      }).catch(() => {
      });
    },
    /** 人工转售后 */
    handleManualAfterSale (row) {
      this.applySaleParams.sku_id = row.sku_id;
      this.applySaleParams.order_sn = row.order_sn;
      this.manualAfterSaleProxy.display();
    },

    handlerManualAfterSaleConfirm () {
      this.$refs.applySaleForm.validate((valid) => {
        if (valid) {
          this.applySaleParams['refund_img'] = this.fileList.map(item => item.url).join(',')
          API_order.aiComboShopApplyAfterSale(this.applySaleParams).then(res => {
            if (res) {
              this.$message.success('操作成功');
              this.manualAfterSaleProxy.dismiss();
              this.GET_OrderDetail();
            }
          })
        } else {
          this.$message.error('请检查表单！！！')
        }
      })
      return false;
    },

    handleCloseDialog () {
      this.fileList = [];
      this.manualAfterSaleProxy.dismiss();
      return false;
    },

    /** 上传图片校验 */
    handleBeforeUpload (file) {
      const isSatisfy = ["image/jpeg", "image/png"].indexOf(file.type) !== -1;
      if (!isSatisfy) {
        this.$message.error("图片仅支持 jpg、png 格式!");
      }
      return isSatisfy;
    },
    handleUploadSuccess (response) {
      console.log(response)
      this.fileList.push(response)
    },
    handleUploadRemove (file, fileList) {
      console.log(file, fileList)
      this.fileList = fileList;
    },
    handleUploadExceed () {
      this.$message.error('凭证最多上传9张')
    },

    /** 以下是 选择其他商品相关方法 */
    handleOpenSelectOtherDialog (row) {
      this.replaceGoodsParams.goods_id = row.combo_goods_id || row.goods_id
      this.replaceGoodsParams.shop_combo_id = this.orderDetail.shop_combo_id
      this.replaceGoodsParams.card_id = this.comboInfo.card_id
      // this.selectOtherGoodsForm.card_key = this.orderDetail.card_key;
      this.selectOtherGoodsForm.source_order_sn = row.order_sn;
      this.selectOtherGoodsForm.trade_item_param_volist[0]['source_goods_id'] = row.goods_id;
      this.selectOtherGoodsProxy.display()
    },

    handleCloseSelectOtherDialog () {
      this.selectOtherGoodsForm = {
        remark: '',
        addr_id: '',
        trade_item_param_volist: [
          {
            source_goods_id: '',
            sku_id: ''
          }
        ],
        trade_kind: 3,
        source_order_sn: ''
      }
      this.selectGoods = {};
      this.selectOtherGoodsProxy.dismiss();
      return false;
    },

    handleConfirmSelectOtherDialog () {
      this.selectOtherGoodsForm.addr_id = this.selectAddressId;
      this.selectOtherGoodsForm.trade_item_param_volist[0]['num'] = this.selectGoods.goods_num;
      this.selectOtherGoodsForm.trade_item_param_volist[0]['sku_id'] = this.selectGoods.goods_id;
      API_order.createChangeGoodsTrade(this.selectOtherGoodsForm).then(res => {
        if (res.trade_sn) {
          this.$message.success('操作成功');
          this.selectOtherGoodsProxy.dismiss();
          this.GET_OrderDetail();
          // 重置表单
          this.selectOtherGoodsForm = {
            remark: '',
            addr_id: '',
            trade_item_param_volist: [
              {
                source_goods_id: '',
                sku_id: ''
              }
            ],
            trade_kind: 3,
            source_order_sn: ''
          };
          this.selectGoods = {}
        }
      })
      return false;
    },
    handleOpenGoodsPicker () {
      request({
        url: `seller/goods/getReplaceGoods`,
        method: 'get',
        params: this.replaceGoodsParams
      }).then(res => {
        this.changeGoods = res
        this.selectChangeGoods.display()
      })
    },

    handleSelectGoods (row) {
      this.selectGoods = row
      this.selectChangeGoods.dismiss()
    },

    /** 添加收货地址 */
    handlerSelectAddress (addr_id) {
      this.selectAddressId = addr_id;
      this.$forceUpdate();
    },

    handlerAddAddressDialog (type) {
      if (type === 1) {
        this.addAddressForm = {
          name: '',
          addr: '',
          mobile: '',
          def_addr: 0,
          region: ''
        }
        this.addAddressDialog.display()
      } else {
        this.addAddressDialog.dismiss();
      }
      return false;
    },

    /** 地址选择change方法 */
    handlerAddressSelectorChange (object) {
      this.addAddressForm.region = object.last_id;
    },

    handlerAddAddressConfirm () {
      this.addAddressForm['member_id'] = this.orderDetail.member_id;
      this.$refs.addAddressForm.validate(valid => {
        if (valid) {
          this.addAddressForm.shop_id = this.$store.getters.shopInfo.shop_id // 店铺
          API_order.addMemberAddress(this.addAddressForm, this.orderDetail.card_key).then(res => {
            if (res.name) {
              this.$message.success('添加成功');
              this.addAddressDialog.dismiss();
              this.getReceiveAddress();
              this.addAddressForm = {
                name: '',
                addr: '',
                mobile: '',
                def_addr: 0,
                region: ''
              }
            }
          })
        } else {
          this.$message.error('请检查表单！！！')
        }
      })
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .wlcz {
  list-style: none;
  margin-bottom: 0;
  padding-left: 10px;
}

/deep/ .cell {
  text-align: center;
}

.logistics-info {
  background-color: #fff;
  padding: 10px 10px 50px 10px;

  // text-align: center;
  .ship-item {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &:first-child {
      .ship-dot {
        background-color: #ec3a33;
      }

      p {
        color: #ec3a33;
      }
    }

    &:not(:last-child)::after {
      content: " ";
      position: absolute;
      z-index: 1;
      pointer-events: none;
      background-color: #e5e5e5;
      width: 1px;
      top: 37px;
      left: 10px;
      bottom: -20px;
    }
  }

  .ship-dot {
    position: relative;
    line-height: 20px;
    font-size: 10px;
    text-align: center;
    color: #666;
    margin: 24px 15px 0 5px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ccc;
  }

  .ship-msg {
    flex: 1;
    padding: 15px 0;
  }

  .ship-msg-text {
    color: #333;
    font-size: 14px;
    line-height: 18px;
  }

  .ship-msg-time {
    font-size: 12px;
    color: #999;
  }
}

.table-cont {
  margin-bottom: 0;
  font-size: 13px;
  width: 100%;
  max-width: 100%;

  .detail-tr1 {
    font-size: 12px;
    color: #a6a6a6;
  }
}

.table-cont.order-table td {
  border-top: 0;
}

.table-cont>tbody>tr>td {
  line-height: 1.42857;
  padding: 8px;
  vertical-align: middle;
}

.m-l-md {
  margin-left: 20px !important;
}

.order-detail-container {
  background: #fff;
  position: relative;
  padding: 20px;
  border-radius: 5px;
}

.buttonbc {
  float: right;
  height: 40px;
}

.cont {
  /* width: 1400px; */
  /* margin-left: 80px; */
  margin-left: 30px;
  padding-bottom: 20px;
}

/deep/ .is-leaf {
  /* border: 1px solid #000000 !important; */
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
}

/deep/ .el-table__row td {
  /* border: 1px solid #000000 !important; */
  text-align: center;
  font-size: 16px;
}

/deep/ .el-table--border {
  /* border: 1px solid #000000 !important; */
}

.zongji {
  width: 100%;
  font-size: 26px;
  text-align: right;
  padding-top: 30px;
}

.sendhuo {
  margin-top: 60px;
  float: right;
}

.goods-image {
  width: 50px;
  height: 50px;
}

.el-row {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  color: #a6a6a6;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>

<style lang="scss" scoped>
/deep/ .el-upload-list__item {
  width: 85px;
  height: 85px;
}

/deep/ .el-upload--picture-card {
  width: 85px;
  height: 85px;
  padding: 10px;

  .custom-upload {
    font-size: 22px;
    line-height: 14px;

    p {
      margin: 0;
      font-size: 14px;
    }
  }
}

.address-list {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .address-item {
    display: flex;
    align-items: center;
    font-size: 12px;
    height: 30px;
    margin-bottom: 10px;

    .name-and-province {
      border: 1px solid #cccccc;
      width: 120px;
      text-align: center;
      margin-right: 10px;
      height: 30px;
      line-height: 30px;
      background-color: #ffffff;
      cursor: pointer;
      position: relative;
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      /deep/ .el-icon-check {
        color: #ffffff;
        font-weight: bold;
        position: absolute;
        top: 12px;
        right: 0;
        border-bottom: 8px solid #4869ba;
        border-right: 8px solid #4869ba;
        border-left: 8px solid transparent;
        border-top: 8px solid transparent;

        &:before {
          position: absolute;
          top: -3px;
          left: -3px;
        }
      }
    }

    .name-and-province:hover {
      border: 2px solid #4869ba;
    }

    .inline-block-span {
      display: inline-block;
      margin-right: 5px;
    }
  }

  .address-item:hover {
    background-color: rgba(26, 67, 169, .3);
  }
}

/deep/ .app-address {
  position: relative;
  top: 8px;
}

/deep/ .app-address-body {
  left: 0 !important;
}
</style>
